@import url('https://fonts.googleapis.com/css2?family=Exo+2');
@import './styles/variables.scss';

html {
  font-size: $desktopRootFont;
}

@media screen and (max-width: $fontScalingMaxWidth) {
  html {
    font-size: $desktopRootFont * 0.75;
  }
}

body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
