@import '../../styles/variables.scss';

footer {
  .viewport {
    padding-top: 4.17rem;
    padding-bottom: 6.75rem;
  }

  svg {
    height: 3.33rem;
    width: 11.5rem;
    margin-right: 12rem;
  }


  .aboutContainer {
    margin-right: 9.5rem;
  }

  .categoryItem,
  .infoItem {
    margin: 0 0 1.33rem;
  }

  .categoryItem {
    background-color: transparent;
    color: $white;

    &.active {
      color: $activeColor;
    }

    &:hover {
      color: $hoverColor;
    }
  }
}