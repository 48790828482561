@import '../../styles/mixins.scss';

button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $controlHeight;
  padding: 0 1.58rem;
  border-radius: $controlHeight/2;
  outline: none;
  border-style: solid;
  background-color: $lightColor;
  border-color: $lightColor;
  color: $darkColor;
  font-family: 'Exo 2', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.92rem;
  
  @include interaction;

  &.dark {
    background-color: $darkColor;
    border-color: $darkColor;
    color: $lightColor;
    &.outlined {
      color: $darkColor;
    }
    @include interaction;
  }

  &.outlined {
    background-color: transparent;
    border-color: $lightColor;
    color: $lightColor;

    @include interaction;

    &.dark {
      border-color: $darkColor;
      @include interaction;
    }
  }

  svg {
    height: 0.92rem;
    margin-left: 0.83rem;
  }
}