.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  p, h2 {
    width: 29.67rem;
  }

  h2 {
    margin-top: 5.25rem;
    margin-bottom: 0.83rem;
  }

  p {
    margin: 0;
  }
}