@import '../../../../../styles/variables.scss';

.aboutUs {
  margin-left: 10rem;

  p {
    margin-bottom: 9.5rem;
  }

  .bigBlock {
    p {
      margin: 0;
    }
  }

  .bigData {
    justify-content: space-between;
  }
}

.showOff {
  justify-content: space-between;
}

.benefits {
  color: $white;
  pointer-events: none;


  .benefitsList {
    display: flex;
    flex-direction: column;
    flex: 1;

    .benefitBlock {
      &:not(:last-child) {
        border-bottom: 0.08rem solid $white;
        margin-bottom: 2.5rem;
      }

      p{
        margin-bottom: 2.5rem;
      }
    }
  }

  .benefitsListSpacer {
    padding-right: 10rem
  }
}

@media screen and (max-width: $desktopMaxWidth) {
  img {
    max-width: 100%;
  }
}
