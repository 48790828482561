@import "../../../../../styles/variables.scss";

.group {
  border-bottom: 0.08rem solid $darkColor;
  padding: 6.67rem 0;

  .left {
    width: $categoryLeftContentWidth;
  }

  .right {
    width: $categoryRightContentWidth ;
  }

  @media screen and (max-width: $desktopMaxWidth) {
    .left {
      width: 100%;
      margin-bottom: 2rem;
    }

    .right {
      width: 100%;
    }
  }

  .carousel {
    .arrowContainer {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        .arrow {
          background-color: $hoverColor;
          border-color: $hoverColor;
          color: $darkColor;
        }
      }
    
      &.active,
      &:active {
        .arrow {
          background-color: $activeColor;
          border-color: $activeColor;
          color: $darkColor;
        }
      }
    
      &:disabled {
        .arrow {
          background-color: $disabledColor;
          border-color: $disabledColor;
          color: $darkColor;
        }
      }

      .arrow {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.08rem solid $black;
        background-color: $white;

        &::before {
          color: $black;
        }

        &Left {
          margin-right: 2rem;

          &::before {
            content: url('../../../../../svg/arrow-left.svg');
          }
        }

        &Right {
          // margin-left: 2rem;

          &::before {
            content: url('../../../../../svg/arrow-right.svg');
          }
        }
      }
    }
  }
}