@import '../../styles/variables.scss';

$borderWidth: 0.08rem;

.inputGroup {
  label {
    padding-top: 2.17rem;
    padding-bottom: 0.83rem;
  }

  input {
    height: $controlHeight - $borderWidth*2;
    border: $borderWidth solid $borderColor;
    border-radius: 0.16rem;
    padding: 0 1.67rem;
  }
}