@import '../../../../../../../styles/variables.scss';

$clientCardSize: 13.83rem;

.clientCard {
  width: $clientCardSize;
  height: $clientCardSize;

  border-radius: 0.83rem;
  background-color: $white;
  
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 2rem;
  margin-bottom: 2.5rem;

  img {
    scale: $clientCardImageScale;
  }
}
