@import "../../../../styles/variables.scss";

.infoItem {
  margin: 0 1.5rem;
  color: $textLightColor;

  &:hover {
    color: $hoverColor;
    cursor: pointer;
  }

  &.active,
  &:active {
    color: $activeColor;
    cursor: pointer;
  }

  &:disabled {
    color: $disabledColor;
  }
}