$white: white;
$black: #0E1219;
$green: #3BBC75;
$greenLight: #35ED77;
$greenLightOpacity: #35ed7766;
$greyDark: #DEDEDE;
$greyLight: #F9F9F9;


$backgroundDarkColor: $black;
$backgroundColor: $white;
$textLightColor: $white;
$textDarkColor: $black;
$darkColor : $black;
$lightColor : $white;
$hoverColor: $green;
$activeColor: $greenLight;
$disabledColor: $greyDark;
$borderColor: $black;

$viewportWidth: 93rem;
$controlHeight: 4rem;

$categoryLeftContentWidth: $viewportWidth * (2/5);
$categoryRightContentWidth: $viewportWidth - $categoryLeftContentWidth;

$clientCardImageScale: 0.5;

$panelHeigh: 8.33rem;

$desktopRootFont: 12px;
$desktopMaxWidth: 850px;
$fontScalingMaxWidth: 1115px;