@import '../../../../../styles/variables.scss';

.ourClients {
  .left{
    flex: 4;

    h2 {
      margin-bottom: 4.17rem;
    }

    button {
      margin-bottom: 1.67rem;
    }
  }

  .right {
    flex: 5;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
