@import "./variables.scss";

@mixin interaction {
  &:hover {
    background-color: $hoverColor;
    border-color: $hoverColor;
    color: $darkColor;
  }

  &.active,
  &:active {
    background-color: $activeColor;
    border-color: $activeColor;
    color: $darkColor;
  }

  &:disabled {
    background-color: $disabledColor;
    border-color: $disabledColor;
    color: $darkColor;
  }
}