@import '../../../styles/variables.scss';

.category {
  background-color: $greyLight;
  padding-top: 4.17rem;
  padding-bottom: 5.83rem;
}

.categoryDescription {
  .left {
    width: $categoryLeftContentWidth;
  }

  .right {
    width: $categoryRightContentWidth;
  }

  @media screen and (max-width: $desktopMaxWidth) {
    .left {
      width: 100%;
    }

    .right {
      width: 100%;
    }   
  }
}
