@import '../../styles/variables.scss';

.viewportContainer {
  display: flex;
  justify-content: center;

  &.dark {
    background-color: $backgroundDarkColor;
  }

  &.gray {
    background-color: $greyLight;
  }

  &.extraPadding {
    padding: 5.83rem 0;
  }
}



@media screen and (min-width: $desktopMaxWidth) {
  .viewport {
    width: $viewportWidth;
  }
}

@media screen and (max-width: $desktopMaxWidth) {
  .viewport {
    width: 90%;
  }
}