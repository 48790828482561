@import "../../../../../styles/variables.scss";

$productItemMargin: 1rem;
$productItemMargin: 1rem;
$productItemBorderRadius: 0.83rem;
$productItemImageHeight: 28.3rem;
$productItemContainerWidth: 21.5rem;


.productImage {
  height: $productItemImageHeight;
}

.productItem {
  height: $productItemImageHeight;

  .flex {
    justify-content: center;
    align-items: center;
    background: $greyLight;
    border-radius: $productItemBorderRadius;
    width: $productItemContainerWidth;
    height: 100%;
    overflow: hidden;
  }


  &:hover {
    .productName {
      opacity: 1;
    }
  }

  .productName {
    position: absolute;
    bottom: 0;
    width: $productItemContainerWidth;
    border-radius: $productItemBorderRadius;
    background: linear-gradient(180deg, rgba(14, 18, 25, 0.3) 18.82%, rgba(14, 18, 25, 0.21) 118.82%);
    backdrop-filter: blur(5px);
    opacity: 0;


    p {
      padding-left: 1.67rem;
      color: $white;
      text-transform: capitalize;
    }
  }
}