@import "./variables.scss";

.landing {
  background-color: cadetblue;
  .categoryItem {
    background-color: beige;
  }
}

.product {
  background-color: cadetblue;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
}

@media screen and (max-width: $desktopMaxWidth) {
  .flexRow {
    flex-direction: column;
  }
}

.center {
  justify-content: center;
  align-items: center;
}

.h1,
h1 {
  font-family:'Exo 2', sans-serif;
  font-size: 2.67rem;
  font-weight: 600;
  line-height: 3.17rem;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.h2,
h2 {
  font-family:'Exo 2', sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.42rem;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.h3,
h3 {
  font-family: 'Exo 2', sans-serif;
  font-size: 1.17rem;
  font-weight: 500;
  line-height: 1.47rem;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.h4,
h4 {
  font-family: 'Exo 2', sans-serif;
  font-size: 1.17rem;
  font-weight: 700;
  line-height: 1.47rem;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 1.67rem;
}

.b18 {
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.97rem;
  letter-spacing: 0em;
  text-align: left;
}

p,
a,
.b1 {
  //styleName: Body 1;
  font-family: 'Exo 2', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.47rem;
  letter-spacing: 0em;
  text-align: left;
}

a {
  margin-bottom: 0.67rem;
  color: $darkColor;
  
  &:visited {
    color: $greenLightOpacity;
  }
  
  &:focus,
  &:hover {
    color: $hoverColor;
  }
  
  &:active {
    color: $activeColor;
  }
}

big {
  font-style: bold;
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.83rem;
  text-transform: uppercase;  
  color: $white;
  text-shadow:
    -0.08rem -0.08rem 0 $green,
     0   -0.08rem 0 $green,
     0.08rem -0.08rem 0 $green,
     0.08rem  0   0 $green,
     0.08rem  0.08rem 0 $green,
     0    0.08rem 0 $green,
    -0.08rem  0.08rem 0 $green,
    -0.08rem  0   0 $green;
}

small {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.42rem;
  text-transform: uppercase;
  color: $green;
}

.highlightText {
  color: $green;
  display: inline-block;
}