@import '../../../../styles/variables.scss';

.panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $backgroundDarkColor;
  height: $panelHeigh;
  color: $white;
}

.fixed {
  position: sticky;
  top: 0;
  z-index: 9999;
}
