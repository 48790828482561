@import '../../styles/variables.scss';

.categoriesPanel {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  button {
    margin: 0 1.25rem;
    flex: 1;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: $desktopMaxWidth) {
    button {
      margin: 0;
      padding: 0 1rem;
      height: $controlHeight*0.7;
      font-size: 0.7rem;
      border-width: 1px;
    }
  }
}

.stickyCategories {
  position: sticky;
  top: $panelHeigh;
  z-index: 9999;
}